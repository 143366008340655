<template>
    <div class="videobox" >
        <div @click="$emit('closeVideoBox')" class="close">x</div>
        <!-- <video class="videobox__obj" :src="urls" autoplay controls width="100%"></video> -->
        <div style="height:100%;width:100%" v-html="urls">

        </div>
    </div>
</template>

<script>
export default {
    props: ['urls'],

}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 769px) {
    .close{
        position: absolute;
        right: 100PX  !important; 
        top: 0px;
        color:#fff;
        font-size: 60px;
        z-index: 2;
        font-weight: 700;
        cursor: pointer;
    }
}
.videobox {
    overflow: auto;
    position: fixed;
    background: #000 ;
    z-index:9999;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    .close{
        position: absolute;
        right: 10PX;
        top: 0px;
        color:#fff;
        font-size: 40px;
        z-index: 2;
        font-weight: 700;
        cursor: pointer;
    }
    &__obj {
        width: 100%;
        height: 100%;
        background-color: rgb(88, 86, 86);
        z-index: 1;
    }
}
</style>