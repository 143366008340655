<template>
  <div class="Welfare">
    <video-vue :urls="videourl" @closeVideoBox="() => { showVideobox = false }" v-if="showVideobox"></video-vue>
    <nav-vue />
    <div class="banner">
      <img class="banner__img" :src="config.public_welfare_banner" alt="">
    </div>
    <img class="content__could" src="@/assets/image/rd/could.png" alt="" />
    <!-- pc -->
    <div v-if="!isphone">
      <div class="idea">
        <div class="idea__title">
          公益理念
        </div>
        <div class="idea__info">
          {{ config.public_welfare_content }}
        </div>
      </div>
      <div class="sctivity">
        <div class="sctivity__title">公益活动</div>
        <div class="sctivity__infoBox">
          <div v-for="(item, index) in  activityData" :key="index" class="sctivity__infoBox-item">
            <div class="sctivity__infoBox-itemvideo">
              <img class="sctivity__infoBox-itemvideo__cover" :src="item.cover" alt="" />
              <img @click="Onshwovideobox(item.content)" class="sctivity__infoBox-itemvideo__play"
                src="@/assets/image/Welfare/play.png" alt="" />
            </div>
            <div class="sctivity__infoBox-itembox">
              <H3 class="sctivity__infoBox-itembox__title">{{ item.title }}</H3>
              <!-- <div class="sctivity__infoBox-itembox__date">{{ new Date(item.updatetime).toLocaleString() }}</div> -->
              <!-- <p class="sctivity__infoBox-itembox__content">{{  }}</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="donation">
        <div class="donation__title">捐赠通道</div>
        <div class="donation__box">
          <!-- <el-form ref="Form" :model="form">
            <div class="donation__box__one">
              <el-form-item :rules="[
                { required: true, message: '姓名不能为空' },
              ]" prop="name">
                <el-input placeholder="姓名*" v-model="form.name" clearable>
                </el-input>
              </el-form-item>
              <el-form-item class="code">
                <img style="witdh:100px;height:100px" src="../assets/code.jpg" alt="">
                <div>扫码进入捐赠通道</div>
              </el-form-item>
              <el-form-item :rules="[
                { required: true, message: '电话不能为空' },
              ]" required prop="phone">
                <el-input placeholder="电话*" v-model="form.phone" clearable>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item :rules="[
              { required: true, message: '标题不能为空' },
            ]" class="donation__box__two" required prop="title">
              <el-input placeholder="标题*" v-model="form.title" clearable>
              </el-input>
            </el-form-item>
            <el-form-item :rules="[
              { required: true, message: '留言不能为空' },
            ]" class="donation__box__two" required prop="content">
              <el-input type="textarea" autosize placeholder="留言内容*" v-model="form.content">
              </el-input>
            </el-form-item>
            <button class="onsend" @click.prevent="onsend">发送邮件</button>
          </el-form> -->
          <img style="witdh:300px;height:300px" src="../assets/code.jpg" alt="">
          <div>扫码进入捐赠通道</div>
        </div>
      </div>
    </div>
    <!-- 手机页 -->
    <div v-else>
      <div class="idea">
        <div class="idea__title">
          公益理念
        </div>
        <div class="idea__info">
          {{ config.public_welfare_content }}
        </div>
      </div>
      <div class="sctivity">
        <div class="sctivity__title">公益活动</div>
        <div class="sctivity__infoBox">
          <div v-for="(item, index) in  activityData" :key="index" class="sctivity__infoBox-item">
            <div class="sctivity__infoBox-itemvideo">
              <img class="sctivity__infoBox-itemvideo__cover" :src="item.cover" alt="" />
              <img @click="Onshwovideobox(item.content)" class="sctivity__infoBox-itemvideo__play"
                src="@/assets/image/Welfare/play.png" alt="" />
            </div>
            <div class="sctivity__infoBox-itembox">
              <H3 class="sctivity__infoBox-itembox__title">{{ item.title }}</H3>
              <!-- <div class="sctivity__infoBox-itembox__date">{{ new Date(item.updatetime).toLocaleString() }}</div> -->
              <!-- <p class="sctivity__infoBox-itembox__content">{{ item.content }}</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="donation">
        <div class="donation__title">捐赠通道</div>
        <div class="donation__box">
          <!-- <el-form ref="Form" :model="form">
            <div class="donation__box__one">
              <el-form-item :rules="[
                { required: true, message: '姓名不能为空' },
              ]" prop="name">
                <el-input placeholder="姓名*" v-model="form.name" clearable>
                </el-input>
              </el-form-item>
              <el-form-item>
                <img style="witdh:100px;height:100px" src="../assets/code.jpg" alt="">
                <div>扫码进入捐赠通道</div>
              </el-form-item>
              <el-form-item :rules="[
                { required: true, message: '电话不能为空' },
              ]" required prop="phone">
                <el-input placeholder="电话*" v-model="form.phone" clearable>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item :rules="[
              { required: true, message: '标题不能为空' },
            ]" class="donation__box__two" required prop="title">
              <el-input placeholder="标题*" v-model="form.title" clearable>
              </el-input>
            </el-form-item>
            <el-form-item :rules="[
              { required: true, message: '留言不能为空' },
            ]" class="donation__box__two" required prop="content">
              <el-input type="textarea" autosize placeholder="留言内容*" v-model="form.content">
              </el-input>
            </el-form-item>
            <button class="onsend" @click.prevent="onsend">发送邮件</button>
          </el-form> -->
          <img style="witdh:200px;height:200px" src="../assets/code.jpg" alt="">
          <div>扫码进入捐赠通道</div>
        </div>
      </div>
    </div>
    <footer-vue></footer-vue>
  </div>
</template>

<script>
import NavVue from '../components/nav.vue'
import FooterVue from '../components/footer.vue'
import VideoVue from '../components/video.vue';
import { activityIndex, messageAdd } from '@/uilt/api'
import config from '../mixin/config'
export default {
  mixins: [config],
  components: { NavVue, FooterVue, VideoVue },
  data() {
    return {
      activityData: [],
      form: {
        name: '',
        email: '',
        phone: '',
        title: '',
        content: ''
      },
      videourl: '',
      showVideobox: false,
      listActive: 0
    }
  },
  created() {
    this.getactivityIndex()
  },
  methods: {
    Onshwovideobox(e) {
      this.videourl = e
      this.showVideobox = true;
    },
    Onlist(e) {
      this.listActive = e
    },
    getactivityIndex() {
      activityIndex().then(res => {
        this.activityData = res
      })
    },
    onsend(e) {
      this.$refs.Form.validate((e) => {
        if (e) {
          this.SendMessageAdd()
        }
      })
    },
    clearData() {
      this.form.name = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.title = ''
      this.form.content = ''
      this.$refs.Form.resetFields()
    },
    SendMessageAdd() {
      messageAdd(this.form).then(res => {
        if (res.code === 1) {
          this.$message({
            type: 'success',
            message: res.msg
          })
          this.clearData()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
   .el-form-item__content {
     line-height: 10px;
   }

   .el-input {
     input {
       background: #E0CFB1;
       font-size: 14px;
       font-family: Songti SC;
       font-weight: 300;
       color: #000;

       &::placeholder {
         font-size: 14px;
         font-family: Songti SC;
         font-weight: 300;
         color: #181818;
         opacity: 0.7;
       }
     }
   }
 }

 .Welfare {}

 .banner {
   width: 100%;
   height: 400px;

   &__img {
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
 }

 .content {


   &__could {
     width: 406px;
     height: 482px;
     position: absolute;
     top: 770px;
     left: 0;
     z-index: 0;
     pointer-events: none;
   }

 }

 @media screen and (min-width: 769px) {
   .idea {
     margin-top: 62px;

     &__title {
       width: 254px;
       height: 74px;
       background-image: url('../assets/image/Welfare/title.png');
       background-size: 100%;
       line-height: 74px;
       font-size: 36px;
       font-family: FZLiShu-S01Ss;
       font-weight: 400;
       color: #FFFFFF;
       margin: auto;
     }

     &__info {
       width: 1260px;
       height: auto;
       margin: 17px auto 0 auto;
       background-image: url('../assets/image/Welfare/itembg.png');
       background-size: 100% 100%;
       background-repeat: no-repeat;
       padding: 60px 133px 60px 88px;
       box-sizing: border-box;
       text-align: left;
       font-size: 16px;
       font-family: Songti SC;
       font-weight: 400;
       color: #333333;
       line-height: 28px;
     }
   }

   .sctivity {
     margin-top: 88px;

     &__title {
       width: 254px;
       height: 74px;
       background-image: url('../assets/image/Welfare/title.png');
       background-size: 100%;
       line-height: 74px;
       font-size: 36px;
       font-family: FZLiShu-S01Ss;
       font-weight: 400;
       color: #FFFFFF;
       margin: auto;
     }

     &__infoBox {
       margin: 80px auto 0 auto;
       display: flex;
       text-align: left;
       width: 1330px;
       flex-wrap: wrap;

       &-item {
         width: 388px;
         height: 470px;
         background-image: url('../assets/image/Welfare/itembgs.png');
         background-size: 100%;
         position: relative;
         margin: auto;
         padding: 0 65px;
         box-sizing: border-box;

         &video {
           margin-top: 55px;
           margin-left: -8px;

           &__play {
             width: 38px;
             height: 43px;
             position: absolute;
             top: 124px;
             left: 50%;
             transform: translateX(-50%);
             cursor: pointer;
           }

           &__cover {
             width: 270px;
             height: 178px;
           }
         }

         &box {
           position: absolute;
           width: 266px;
           top: 200px;

           &__title {
             font-size: 18px;
             font-family: Songti SC;
             font-weight: bold;
             color: #000000;
             margin: 50px 0 0 0;

           }

           &__date {
             margin-top: 10px;
             padding: 0;
             font-size: 14px;
             font-family: Songti SC;
             font-weight: 400;
             color: #000000;
             line-height: 30px;
           }

           &__content {
             margin: 0;
             font-size: 14px;
             font-family: Songti SC;
             font-weight: 400;
             color: #333333;
             line-height: 22px;
             text-overflow: ellipsis;
             display: -webkit-box;
             -webkit-line-clamp: 3;
             -webkit-box-orient: vertical;
             display: -moz-box;
             -moz-line-clamp: 3;
             -moz-box-orient: vertical;
             overflow-wrap: break-word;
             word-break: break-all;
             white-space: normal;
             overflow: hidden;
           }
         }
       }
     }
   }

   .donation {
     margin: 97px 0 74px 0;
     background-image: url('../assets/image/Welfare/cloud.png');
     background-size: 100%;
     background-position: bottom;

     &__title {
       width: 254px;
       height: 74px;
       background-image: url('../assets/image/Welfare/title.png');
       background-size: 100%;
       line-height: 74px;
       font-size: 36px;
       font-family: FZLiShu-S01Ss;
       font-weight: 400;
       color: #FFFFFF;
       margin: auto;
     }

     &__box {
       margin: 61px auto 0 auto;
       background-image: url('../assets/image/Welfare/bg.png');
       background-size: 100%;
       width: 1400px;
       height: 663px;
       padding: 169px 0 0 0;
       box-sizing: border-box;

       &__one {
         width: 1056px;
         margin: auto;
         display: flex;
         justify-content: space-between;
         height: 100px;
        position: relative;
         .code {
           position: absolute;
           left: 50%;
           transform: translate(-50%,-30%);
         }

         ::v-deep {
           .el-input {
             width: 326px;
             height: 46px;
           }
         }
       }

       &__two {
         margin: 26px auto 0 auto;
         width: 1056px;

       }

       .onsend {
         width: 477px;
         height: 60px;
         background: #CA9430;
         border-radius: 2px;
         border: none;
         margin: 63px auto 0 auto;
         font-size: 20px;
         font-family: Songti SC;
         color: #FFFFFF;
         cursor: pointer;
       }

       .onsend:active {
         background: #c8a564;
       }

       ::v-deep {
         .el-textarea {
           height: 131px;
           width: 1056px;
           margin-top: 25px;
           background: #E0CFB1;

           textarea {
             width: 100%;
             height: 100% !important;
             background: #E0CFB1;

             &::placeholder {
               font-size: 14px;
               font-family: Songti SC;
               font-weight: 300;
               color: #181818;
               opacity: 0.7;
             }
           }
         }
       }
     }
   }
 }

 @media screen and (max-width: 768px) {
   .idea {
     margin-top: 62px;
     padding: 0 20px;

     &__title {
       width: 254px;
       height: 74px;
       background-image: url('../assets/image/Welfare/title.png');
       background-size: 100%;
       line-height: 74px;
       font-size: 36px;
       font-family: FZLiShu-S01Ss;
       font-weight: 400;
       color: #FFFFFF;
       margin: auto;
     }

     &__info {
       width: auto;
       height: auto;
       margin-top: 17px;
       background-image: url('../assets/image/Welfare/itembg.png');
       background-size: 100% 100%;
       background-repeat: no-repeat;
       padding: 60px 133px 60px 133px;
       box-sizing: border-box;
       text-align: left;
       font-size: 16px;
       font-family: Songti SC;
       font-weight: 400;
       color: #333333;
       line-height: 28px;
     }
   }

   .sctivity {
     margin-top: 88px;

     &__title {
       width: 254px;
       height: 74px;
       background-image: url('../assets/image/Welfare/title.png');
       background-size: 100%;
       line-height: 74px;
       font-size: 36px;
       font-family: FZLiShu-S01Ss;
       font-weight: 400;
       color: #FFFFFF;
       margin: auto;
     }

     &__infoBox {
       margin: auto;
       flex-wrap: wrap;

       &-item {
         width: 388px;
         height: 470px;
         background-image: url('../assets/image/Welfare/itembgs.png');
         background-size: 100%;
         position: relative;
         margin: auto;
         margin-top: 55px;
         padding: 0 65px;
         box-sizing: border-box;

         &video {
           padding-top: 55px;
           margin-left: -8px;

           &__play {
             width: 38px;
             height: 43px;
             position: absolute;
             top: 124px;
             left: 50%;
             transform: translateX(-50%);
             cursor: pointer;
           }

           &__cover {
             width: 270px;
             height: 178px;
           }
         }

         &box {
           position: absolute;
           width: 266px;
           top: 200px;

           &__title {
             font-size: 18px;
             font-family: Songti SC;
             font-weight: bold;
             color: #000000;
             margin: 50px 0 0 0;

           }

           &__date {
             margin-top: 10px;
             padding: 0;
             font-size: 14px;
             font-family: Songti SC;
             font-weight: 400;
             color: #000000;
             line-height: 30px;
           }

           &__content {
             margin: 0;
             font-size: 14px;
             font-family: Songti SC;
             font-weight: 400;
             color: #333333;
             line-height: 22px;
             text-overflow: ellipsis;
             display: -webkit-box;
             -webkit-line-clamp: 3;
             -webkit-box-orient: vertical;
             display: -moz-box;
             -moz-line-clamp: 3;
             -moz-box-orient: vertical;
             overflow-wrap: break-word;
             word-break: break-all;
             white-space: normal;
             overflow: hidden;
           }
         }
       }
     }
   }

   .donation {
     margin: 97px 0 74px 0;
     background-image: url('../assets/image/Welfare/cloud.png');
     background-size: 100% 100%;
     background-position: bottom;

     &__title {
       width: 254px;
       height: 74px;
       background-image: url('../assets/image/Welfare/title.png');
       background-size: 100%;
       line-height: 74px;
       font-size: 36px;
       font-family: FZLiShu-S01Ss;
       font-weight: 400;
       color: #FFFFFF;
       margin: auto;
     }

     &__box {
       margin: 61px auto 0 auto;
       background-image: url('../assets/image/Welfare/bg.png');
       background-size: 100% 100%;
       width: auto;
       height: 663px;
       padding: 169px 20px 0 20px;

       &__one {
         width: 100%;
         margin: auto;
         justify-content: space-between;

         ::v-deep {
           .el-input {
             width: 100%;
             height: 46px;
           }
         }
       }

       &__two {
         margin: 26px auto 0 auto;
         width: 100%;

       }

       .onsend {
         width: 477px;
         height: 60px;
         background: #CA9430;
         border-radius: 2px;
         border: none;
         margin: 63px auto 0 auto;
         font-size: 20px;
         font-family: Songti SC;
         color: #FFFFFF;
         cursor: pointer;
       }

       .onsend:active {
         background: #c8a564;
       }

       ::v-deep {
         .el-textarea {
           height: 131px;
           width: 100%;
           margin-top: 25px;
           background: #E0CFB1;

           textarea {
             width: 100%;
             height: 100% !important;
             background: #E0CFB1;

             &::placeholder {
               font-size: 14px;
               font-family: Songti SC;
               font-weight: 300;
               color: #181818;
               opacity: 0.7;
             }
           }
         }
       }
     }
   }
 }
</style>